import React from 'react';
import Seo from '../components/info-site/Seo';
import Layout from '../layouts/en';
// import {
// 	integrationHostGuideFaqData,
// 	integrationSupplierGuideFaqData,
// } from '../data/integrationGuideFaqData';
// import GapIntegrationGuide from '../components/info-site/GapIntegrationGuide';

// import Step1 from '../assets/step-1.png';
// import Step2 from '../assets/step-2.png';
// import Step3 from '../assets/step-3.png';

const GapGuide = (props) => {
	// const [host, setHost] = useState(true);

	// const handleHost = () => {
	// 	setHost(!host);
	// };
	return (
		<Layout location={props.location}>
			<Seo titleId="gapGuideTitle" descriptionId="rewardsGuideDescription" />

			{/* <GapIntegrationGuide
				host={host}
				hostFaq={integrationHostGuideFaqData}
				advFaq={integrationSupplierGuideFaqData}
				handleHost={handleHost}
				hero={{
					title: 'gapGuideTitle',
					description: 'integrationGuideDescription',
					questions: 'integrationGuideQuestions',
					link: {
						url: '/contact#contact-form',
						text: 'emailUs',
					},
				}}
				testimonials={{
					partners: ['Forthglade'],
					showPersonName: true,
				}}
				steps={
					host
						? [
								{
									id: 'rewards-step-1',
									label: 'stepOne',
									title: 'rewardsGuideHostStepOneTitle',
									description: 'rewardsGuideHostStepOneDescription',
									links: [
										{
											id: 'integration-step-guide-link-1',
											target: '_blank',
											url: 'https://www.youtube.com/watch?v=X49EY_TsHz8',
											label: 'rewardsGuideHostStepOneLinkOne',
										},
										{
											id: 'integration-step-guide-link-2',
											target: '_blank',
											url: 'https://rtbmedia.hubspotpagebuilder.com/book-a-demo',
											label: 'rewardsGuideHostStepOneLinkTwo',
										},
									],
									image: {
										alt: 'Rewards Step 1',
										src: Step1,
									},
								},
								{
									id: 'rewards-step-2',
									reversed: true,
									label: 'stepTwo',
									title: 'rewardsGuideHostStepTwoTitle',
									description: 'rewardsGuideHostStepTwoDescription',
									image: {
										alt: 'Rewards Step 2',
										src: Step2,
									},
								},
								{
									id: 'rewards-step-3',
									label: 'stepThree',
									title: 'rewardsGuideHostStepThreeTitle',
									description: 'rewardsGuideHostStepThreeDescription',
									image: {
										alt: 'Rewards Step 3',
										src: Step3,
									},
								},
								{
									id: 'rewards-step-4',
									label: 'stepFour',
									title: 'rewardsGuideHostStepFourTitle',
									description:
										'rewardsGuideHostStepFourEmptyDescription',
									image: {
										alt: 'Rewards Step 4',
										src: Step3,
									},
								},
						  ]
						: [
								{
									id: 'rewards-step-1',
									label: 'stepOne',
									title: 'rewardsGuideHostStepOneTitle',
									description: 'rewardsGuideHostStepOneDescription',
									links: [
										{
											id: 'integration-step-guide-link-1',
											target: '_blank',
											url: 'https://www.youtube.com/watch?v=GZs7m0-u_YM',
											label: 'rewardsGuideHostStepOneLinkOne',
										},
										{
											id: 'integration-step-guide-link-2',
											target: '_blank',
											url: 'https://rtbmedia.hubspotpagebuilder.com/book-a-demo',
											label: 'rewardsGuideHostStepOneLinkTwo',
										},
									],
									image: {
										alt: 'Rewards Step 1',
										src: Step1,
									},
								},
								{
									id: 'rewards-step-2',
									reversed: true,
									label: 'stepTwo',
									title: 'rewardsGuideSupplyStepTwoTitle',
									description: 'rewardsGuideSupplyStepTwoDescription',
									image: {
										alt: 'Rewards Step 2',
										src: Step2,
									},
								},
								{
									id: 'rewards-step-3',
									label: 'stepThree',
									title: 'rewardsGuideSupplyStepThreeTitle',
									description:
										'rewardsGuideSupplyStepThreeDescription',
									image: {
										alt: 'Rewards Step 3',
										src: Step3,
									},
								},
						  ]
				}
			/> */}
		</Layout>
	);
};

export default GapGuide;
